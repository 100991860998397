.settings-page {
    display: flex;
    align-items: center;
    min-height: 40vh;
}

.settings-container {
    display: flex;
    flex-direction: column;
}

.settings-section {
    padding: 5px 0;
}
 
.update-btn{
    display: block;
    box-sizing: border-box;
    padding: 15px 12px;
    margin-bottom: 10px;
    background-color: #00314C;
    color: white;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
}

.update-btn:hover,
.update-btn:focus {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}

.input-container {
    display: flex;
    align-items: center;
    gap: 50px;
}