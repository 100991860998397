.comptpage {
    background-color: whitesmoke;
}

.comptpage-container {
    display: flex;
    padding: 60px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    min-height: 750px;
}

.comptpage-top {
    display: flex;
    justify-content: end;
    padding: 20px 0 20px 0;
}

.comptpage-top-btn {
    background-color: #042c46;
    padding: 2% 5%;
    border-radius: 8px;
    border: none;
    color: white;
    cursor: pointer;
}

.comptpage-top-btn:hover,
.comptpage-top-btn:focus {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}

.comptpage-table {
    display: flex;
    flex-direction: column;
}
