.profile{
    height: 90%;
    width: 90%;
    padding-left: 3rem;
}

.profile-title{
    text-align: center;
    margin: 4%;
}

.profile-subtitle{
    text-align: center;
    margin-bottom: 1rem;
}
.profile-container{
    display: flex;
    align-items: flex-start;
}

.form-section{
    width: 60%;
}

.pmss-section{
    width: 25%;
    margin-left: 12%;
    margin: 7rem;
}

.profile-form{
    display: flex;
    flex-direction: column;
    margin: 3% 0 0 0;
}

.password-form{
    display: flex;
    flex-direction: column;
    margin: 10% 0 1% 0 0;
}

.profile-label{
 margin: 2%;
}

.profile-input{
padding: 0.5rem;
border: 1px solid gray;
margin: 0 10% 0 20%;
}

.pmss-list{
display: flex;
flex-direction: column;
align-items: center;
}
.pmss-item{
    margin-bottom: 1rem;
}

.pmss-input{
    padding: 5px;
}


.profile-btn{
    background-color: #042C46;
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
    margin: 5%;
    margin-bottom: 10%;
}

.pmss-btn{
    background-color: #042C46;
    padding: 10px 30px;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
    margin: 10%; 
}