.pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: whitesmoke;
    padding-bottom: 40px;
}

/* Button Download */
.download-container {
    display: flex;
    padding-bottom: 30px;
    gap: 50px;
}

/* Css for all buttons */
.pdf-download, .pdf-modal-btn-back, .pdf-new {
    padding: 10px 20px;
    font-size: 16px;
    align-items: flex-start;
    border-radius: 7px;
    border: none;
    color: white;
    background-color: #00314c;
    cursor: pointer;
}

.pdf-download:hover,
.pdf-download:focus,
.pdf-modal-btn-back:hover,
.pdf-modal-btn-back:focus,
.pdf-new:hover,
.pdf-new:focus {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}

/* Body */
.pdf-body {
    display: flex;
    overflow: auto;
    padding: 50px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    background: #fff;
}

/* Download in progress ... */
.pdf-modal-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.pdf-modal-progress-body {
    display: flex;
    align-items: center;
    border: none;
}

/* Download success */
.pdf-modal-end {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pdf-modal-end-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    border-radius: 20px;
    padding: 2%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 35%;
    border: none;
    outline: none;
}

.pdf-modal-end-text-container {
    display: flex;
    justify-content:center;
    line-height: 30px;
    padding: 10px;
    font-size: 14px;
}

.pdf-modal-end-text {
    display: flex;
    flex-direction: column;
    width: 87%;
    gap: 15px;
}

.pdf-modal-end-btn {
    display: flex;
    gap:50px;
    padding: 20px;
}