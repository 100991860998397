.New-Branch {
 width: 100%;
 height: 100%;
}

.new-banch-container {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh; 
  margin: 2% auto; 
  padding: 2%; 
  width: 85vw; 
  max-width: 960px; 
}
.my-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header-form {
  display: flex;
  flex-direction: column;
  width: 94%;
}

.header-form-container{
 display: flex;
 justify-content: space-between;
}

.header-form-left{
width: 30%;
}

.formBranch-btn-sup-champ-specific{
  background-color: transparent;
  color: rgb(136, 131, 131);
  border:none;
  padding: 15% 55%;
  align-items: center;
  margin: 4% 0%;
}

.formBranch-btn-sup-champ-specific:hover{
  background-color: whitesmoke;
}

.header-form-footer{
display: flex;
align-items: center;
justify-content: space-between;
width: 95%;
margin-left: 2%;
}

.header-form-footer-select{
  padding: 2% 5%;
  margin-right: 5%;
}

.input-champ-form{
  margin-top: 4%;
  margin-bottom: 6%;
}
.formBranch-body-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-branch {
 width: 80%;
 display: flex;
 justify-content: center;

}

.box-branch {
  margin-top: 1%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  overflow-x: auto;
  overflow-y: auto;
  width: 100vw; 
  height: 48vh; 
  background-color: #f7f5f6;
  white-space: nowrap;
  padding: 10px 0; 
}



.formBranch-formule-paper {
  margin: 0 10px; 
  padding: 2rem;
  min-width: 300px; 
  width: auto; 
  max-width: 40vw; 
  height: auto; 
  box-shadow: rgba(0, 0, 0, 0.08) 0px 8px 28px;
  border-radius: 10px;
  background-color: white; 
}


.top-form {
  display: flex;
  align-items: center;
}

.btn-ajt-option,
.btn-sup-for {
  color: white;
  cursor: pointer;
  border-radius: 5%;
  border: none;
  padding: 8px 12px;
  display: flex;
  align-items: center;
}



.formBranch-option-btn{
  margin-top: 2%;
}


.formBranche-btn-option-aj {
  background-color: #04446e;
  padding: 1.7%;
  color: white;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5%;
  margin: 7% 0% 2% 60%;
}

.formBranche-btn-option-sup {
  background-color: #dc143c;
  margin-left: 37%;
  padding: 2%;
  color: white;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5%;
}

.formBranche-form-sup{
  background-color: #dc143c;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5%;
  margin: 9.5%;

}

.formBranch-champ-buttons{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5% 5% 5% 5%;
}


.formBranch-btn-ajt-champ{
  background-color: #04446e;
  padding: 0.6rem 2.5rem;
  color: white;
  display: flex;
  align-items: center;
  border: none;
  font-size: medium;
  border-radius: 5%;
}

.formBranch-btn-sup-champ{
  background-color: #dc143c;
  padding: 2.3%;
  color: white;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5%;
  margin-left: 5%;
}

.footer-champ-form {
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.footer-from {
  display: flex;
  justify-content: end;
  width: 90%;
}

.title-option {
  padding: 10px;
  width: 80%;
}

.input-form,
.btns_top {
  gap: 10px;
}

.loading-branch {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200;
  box-Shadow: 24;
  display: flex;
  flex-Direction: column;
  align-Items: center;
  justify-content: center;
}

.title-loading {
  color: #e2e5e7;
  margin-top: 16px;
}

.formBranche-input-champ {
  display: flex;
  align-items: center;
  margin: 8% 3%; 
  width: 100%; 
}

.formBranche-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; 
}

.MuiGrid-item {
  padding: 8px; 
}

.formBranche-input-nom-champ {
  flex-grow: 2; 
  margin-right: 16px; 
}

.formBranche-input-valeur {
  flex-grow: 1; 
  width: 80% ;
}

@media (max-width: 600px) {
  .formBranche-input-nom-champ,
  .formBranche-input-valeur {
    flex-basis: 100%; 
    margin-right: 0; 
    margin-bottom: 8px; 
  }
}

/* Responsive styles */
@media (max-width: 600px) {
  .header-form {
    flex-direction: column;
    gap: 10px;
  }

  .btns_top {
    flex-direction: row;
    justify-content: space-between;
  }
}
