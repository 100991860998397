.container-help {
    max-width: 800px;
    margin: 0 auto;
    padding: 5%;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: auto;
}

.help-btn-back {
    display: block;
    box-sizing: border-box;
    padding: 6px 12px;
    margin-bottom: 100px;
    background-color: #00314c;
    color: white;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-top: 3px;
    transition:
        background-color 0.2s,
        box-shadow 0.2s;
}

.help-btn-back:hover,
.help-btn-back:focus {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}

.listItem {
    margin-bottom: 10px;
    line-height: 20px;
}

.link {
    font-weight: bold;
    color: #00314c;
}

.header1 {
    font-size: calc(16px + 1.2vw);
}

.header2 {
    text-decoration: underline;
    font-size: calc(14px + 0.6vw);
}

.faq {
    font-weight: bold;
}

.image,
.supportImage {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.zoomedIn {
    transform: scale(2);
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -10%);
}

@media (max-width: 768px) {
    .container {
        padding: 10px;
    }

    .header1,
    .header2 {
        font-size: 18px;
    }

    .image {
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .header1 {
        font-size: 16px;
    }

    .header2 {
        font-size: 14px;
    }

    .container {
        padding: 5px;
    }
}
