.updateBranch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 10px;
  font-family: Arial, sans-serif;
  background-color: whitesmoke;
}

.updateBranch-title{
  font-size: x-large;
  display: flex;
  justify-content: center;
}

.updateBranch-form {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 2em;
  border-radius: 10px;
  width: 90%;
}

.updateBranch-label{
  padding: 10px 20px 20px 0;
}

.updateBranch-form h1,
.updateBranch-form h2,
.updateBranch-form h3,
.updateBranch-form h4 {
  color: #333;
}

h2, h4 {
  padding: 15px 0;
}

.updateBranch-form input[type="text"] {
  margin: 0.5em 0;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid gray;
  width: 20%;
}

.updateBranch-form input[type="text"]:hover,
.updateBranch-form input[type="text"]:focus {
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
}

.updateBranch-form button[type="submit"] {
    display: block;
    box-sizing: border-box;
    width: 25%;
    padding: 15px 12px;
    margin-bottom: 10px;
    background-color: #00314C;
    color: white;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin: 30px auto 10px auto;
    transition: background-color 0.2s, box-shadow 0.2s;
}

.updateBranch-form button[type="submit"]:hover,
.updateBranch-form button[type="submit"]:focus {
  background-color: #005073;
  box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}

.update-options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px 0;
}

.update-option-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 0 15px 50px;
  gap: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: calc(50% - 60px);
}

.updateBranch-loading-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Arrière-plan semi-transparent */
}

.updateBranch-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
}

.updateBranch-title-loading {
  margin-top: 20px;
  color: whitesmoke;
}