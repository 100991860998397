.formUpdate {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.formUpdate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 150vh;
    height: auto;
}

.select-box {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-top: 1%;
}

.container-option2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7%;
}

.formUpdate-btn-add:hover {
    background-color: #1b620b;
    cursor: pointer;
}

.select-label {
    display: flex;
    justify-content: left;
    font-size: 1.2em;
    color: #00314c;
    margin-bottom: 5px;
}

.select-option,
.select-champ {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 12px;
    margin-bottom: 10px;
    font-size: 15px;
    background-color: rgba(229, 231, 235, 1);
    border-radius: 8px;
    border: none;
}

.formUpdate-center {
    display: flex;
    flex-direction: column;
}

.formUpdate-select-sup {
    margin-bottom: 3%;
}

.formUpdate-footer {
    padding: 45px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.formUpdate-option-contaire {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    margin-top: 10%;
}

.formUpate-body-option {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.formUpdate-option-btn {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 12px;
    margin-bottom: 20%;
    background-color: #00314c;
    color: white;
    border-radius: 8px;
    border: none;
    font-size: larger;
    cursor: pointer;
    transition:
        background-color 0.2s,
        box-shadow 0.2s;
}

.formUpdate-option2-btn {
    display: block;
    box-sizing: border-box;
    width: 80%;
    padding: 20px 12px;
    background-color: #00314c;
    color: white;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: larger;
    transition:
        background-color 0.2s,
        box-shadow 0.2s;
}

.formUpdate-footer-btn {
    display: block;
    box-sizing: border-box;
    padding: 13px 25px;
    margin: 8px 250px;
    background-color: #00314c;
    color: white;
    border-radius: 8px;
    border: none;
    font-size: larger;
    cursor: pointer;
    transition:
        background-color 0.2s,
        box-shadow 0.2s;
}

.formUpdate-btn-ayant-sup {
    display: block;
    box-sizing: border-box;
    padding: 1% 3%;
    margin-top: 4%;
    margin-left: 5%;
    background-color: #00314c;
    color: white;
    border-radius: 8px;
    border: none;
    height: 45px;
    font-size: 18px;
    cursor: pointer;
    transition:
        background-color 0.2s,
        box-shadow 0.2s;
}

.formUpdate-option-btn:hover,
.formUpdate-option-btn:focus,
.formUpdate-option2-btn:hover,
.formUpdate-option2-btn:focus,
.formUpdate-footer-btn:hover,
.formUpdate-footer-btn:focus,
.formUpdate-btn-ayant-sup:hover,
.formUpdate-btn-ayant-sup:focus {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}

.formUpate-bottom-text {
    padding-top: 50px;
    width: 35%;
    color: #00314c;
    text-align: center;
}

.formUpdate-center-select {
    width: 80%;
    margin-bottom: 15px;
}

.formUpdate-select-pc {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.formUpdate-center-select-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
}

.formUpdate-select-center {
    display: flex;
    align-items: center;
}
