.container {
  width: 100%;
  height: 90vh;
}

.container-choix {
  min-height: 90vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.wrap-choix {
  width: 370px;
  overflow: hidden;
  height: auto;
  padding: 33px 55px 33px 55px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.choix-header{
    display: flex;
    justify-content: center;
}

.choix-btn {
    display: block;
    width: 100%;
    padding: 15px 12px;
    margin-bottom: 10px;
    background-color: #042C46;
    color: white;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-top: 40px;
}

.choix-btn:hover,
.choix-btn:focus {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}