.tp2-container {
    display: flex;
    height: 975px;
    padding: 20px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    box-shadow:
        0px 19px 38px 0px rgba(0, 0, 0, 0.3),
        0px 15px 12px 0px rgba(0, 0, 0, 0.22);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

/* Header */
.tp2-title {
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    font-size: 14px;
}

/* Body */
.tp2-body {
    display: flex;
    padding: 10px 26px;

    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
}

.contract-branch {
    font-weight: 400;
    line-height: 25px;
    padding: 5px 0px;
}

.contract-branch > h3 {
    font-weight: 700;
    text-decoration-line: underline;
}

/* Table */
.table-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    width: 145%;
    max-width: 180%;
    min-width: 100%;
    box-sizing: border-box;
}

.table-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    width: 150%;
    max-width: 180%;
    min-width: 100%;
    box-sizing: border-box;
}

.table-2-falc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    width: 183%;
    max-width: 200%;
    min-width: 100%;
    box-sizing: border-box;
}

.header-table {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    background: #123147;
}

.title-table {
    display: flex;
    padding: 5px 1px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border: 1px solid #666;
    color: #ffffff;
    font-size: 12px;
}

.body-table {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
}

.content-table {
    display: flex;
    padding: 5px 0px;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
    border: 1px solid #666;
    color: #000;
    font-size: 12px;
    font-style: italic;
}

.tarif {
    padding-top: 20px;
}
