/* Importer la police Roboto Flex depuis Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@100..900&display=swap');

body {
    margin: 0;
    background-color: whitesmoke;
    font-family: 'Roboto Flex', sans-serif;
    -webkit-font-smoothing: antialiased; /* Améliorer le rendu sur les écrans WebKit */
    -moz-osx-font-smoothing: grayscale; /* Améliorer le rendu sur macOS */
}

/* Appliquer une police spécifique pour le code */
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    margin: 0;
    padding: 0;
}
