.table {
    border-collapse: collapse;
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 5px;
}

.element {
    color: white;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.elements {
    color: white;
    border: 1px solid white;
    padding: 5px 8px;
    font-size: 13px;
    background-color: #00314c;
}

.title-col {
    color: white;
    border: 1px solid white;
    padding: 7px 10px;
    font-size: 13px;
    background-color: #00314c;
}

.title-tab {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 5px;
    margin-top: 20px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #00314c;
}

.table-container {
    display: flex;
    align-items: center;
    padding-top: 25px;
}
