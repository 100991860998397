.formUser {
    padding: 100px 0px;
    width: 100%;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formUser-container {
    display: flex;
    background-color: white;
    justify-content: center;
    width: auto;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    min-width: 35%;
    min-height: 60%;
}

.formUser-body {
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 5px;
    width: 100%;
}

.formUser-title {
    font-size: x-large;
    display: flex;
    justify-content: center;
}

.formUser-label {
    padding: 5px;
}

.formUser-input {
    border-radius: 5px;
    padding: 7px;
    outline: none;
    border: 1px solid gray;
}

.formUser-input-large {
    width: 225px;
}

.formUser-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 5px;
    padding: 7px;
    outline: none;
    border: 1px solid gray;
    background: white;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="gray" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px 20px;
}

.formUser-input:hover,
.formUser-input:focus,
.formUser-select:hover,
.formUser-select:focus {
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
}
.formUser-center {
    display: flex;
    justify-content: space-between; /* Aligne le premier élément à gauche et le second à droite */
    padding: 10px 0 10px 0 ;
}

.formUser-block {
    display: flex;
    gap: 10px;
}

.formUser-btn {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 12px;
    margin-bottom: 10px;
    background-color: #00314c;
    color: white;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-top: 30px;
    transition:
        background-color 0.2s,
        box-shadow 0.2s;
}

.formUser-btn:hover,
.formUser-btn:focus {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}

.formUser-btn-back {
    display: block;
    box-sizing: border-box;
    padding: 6px 12px;
    margin-bottom: 10px;
    background-color: #00314c;
    color: white;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-top: 30px;
    transition:
        background-color 0.2s,
        box-shadow 0.2s;
}

.formUser-btn-back:hover,
.formUser-btn-back:focus {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}
