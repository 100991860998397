.formStatus {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:15px;
    /* padding-top: 10px; */
}

.formStatus-btn-reset {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 12px 25px;
    background-color: #00314c;
    color: white;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition:
        background-color 0.2s,
        box-shadow 0.2s;
}

.formStatus-btn-reset:hover,
.formStatus-btn-reset:focus {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}

.formStatus-container-main {
    width: 100%;
}

.formStatus-container {
    padding: 65px 120px;
    width: 55%;
    overflow-x: auto;
    overflow-y: auto;
    height: 65vh;
    margin: auto;
}

.formStatus-title {
    text-decoration-line: underline;
}

.select-label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #00314c;
}

.select-input {
    display: block;
    box-sizing: border-box;
    padding: 15px 12px;
    margin-bottom: 10px;
    font-size: 15px;
    background-color: rgba(229, 231, 235, 1);
    border-radius: 8px;
    border: none;
}

.select-input:focus {
    border-color: #66afe9;
    outline: 0;
}

.branch-label,
.branch-select {
    margin-bottom: 10px;
    color: #333;
    font-size: 1.2em;
}

.formule-label,
.formule-select {
    margin-bottom: 10px;
    color: #333;
    font-size: 1.2em;
}

.option-label,
.option-select {
    margin-bottom: 10px;
    color: #333;
    font-size: 1.2em;
}

.regime-label,
.regime-select {
    margin-bottom: 10px;
    color: #333;
    font-size: 1.2em;
}

.champ-label,
.champ-select {
    margin-bottom: 10px;
    color: #333;
    font-size: 1.2em;
}

.formStatus-btn {
    display: block;
    box-sizing: border-box;
    width: 28rem;
    padding: 12px;
    margin-bottom: 20px;
    background-color: #00314c;
    color: white;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    transition:
        background-color 0.2s,
        box-shadow 0.2s;
}

.formStatus-btn:hover,
.formStatus-btn:focus {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}

.formStatus-option-champ {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
    margin-top: 4%;
}

.formStatus-btn-add {
    display: block;
    box-sizing: border-box;
    width: 28rem;
    padding: 12px;
    margin-bottom: 20px;
    margin-right: 10%;
    background-color: #00314c;
    color: white;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    cursor: pointer;
    transition:
        background-color 0.2s,
        box-shadow 0.2s;
}

.formStatus-btn-add:hover {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}

.formStatus-select-default {
    margin-bottom: 15%;
}

.formStatus-header-title {
    display: flex;
    justify-content: space-between;
}

.formStatus-select-bottom {
    margin-top: 5%;
}

.formStatus-select-ayant {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.formStatus-select-ayant-body {
    width: 85%;
}

.formStatus-btn-ayant-del {
    display: block;
    box-sizing: border-box;
    padding: 1% 3%;
    margin-top: 4%;
    margin-left: 5%;
    background-color: #00314c;
    color: white;
    border-radius: 8px;
    border: none;
    height: 45px;
    font-size: 18px;
    cursor: pointer;
    transition:
        background-color 0.2s,
        box-shadow 0.2s;
}

.formStatus-btn-ayant-del:hover {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}

.formStatus-alert {
    display: flex;
    color: #00314c;
    text-align: center;
    padding: 20px 5px 20px 0;
}

.formStatus-footer {
    display: flex;
    justify-content: center;
    padding: 0px 15px 65px 15px;
}
