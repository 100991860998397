#menu__toggle {
    opacity: 0;
}

#menu__toggle:checked + .menu__btn > span {
    transform: rotate(45deg);
}

#menu__toggle:checked + .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
}

#menu__toggle:checked + .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
}

#menu__toggle:checked ~ .menu__box {
    left: 0 !important;
}

.menu__btn {
    position: absolute;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 5;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 3.2px;
    background-color: #ffffff;
    transition-duration: 0.25s;
}

.menu__btn > span::before {
    content: '';
    top: -8px;
}

.menu__btn > span::after {
    content: '';
    top: 8px;
}

#menu__toggle:checked + .menu__btn {
    position: fixed;
}

.menu__box {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    width: 300px;
    height: 100%;
    margin: 0;
    padding: 80px 0;
    list-style: none;
    background-color: #042c46;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    transition-duration: 0.25s;
    z-index: 2;
}

.menu__item {
    display: flex;
    margin-bottom: 20px;
    padding: 12px 24px;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: 0.25s;
}

.menu__item:hover,
.menu__item:focus {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}

.hamburger-menu {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.menu_title {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.menu__dark {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.menu_imag {
    height: 50px;
    padding-bottom: 10px;
}
