.navbar{
    width: 100%;
    height: 10vh;
}

.navbar-container{
    background-color: #042C46;
    color: white;
    display: flex;
    align-items: center;
    height: 10vh;
    justify-content: space-between;
}

.nav-champ1{
  margin-left: 2%;
}

.nav-champ2{
    margin-right: 10px;
    margin-right: 8%;
}


.nav-ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }

  ul{
    list-style: none;
  }
  
  .nav-li {
    margin-left: 20px;
    list-style: none;

  }

.nav-form-search{
 background-color: #073f64;
 border-radius: 5px;
 display: flex;
 align-items: center;
}

.nav-input-search{
   padding: 5px;
   background-color: transparent;
   border: none;
   outline: none;

}

.nav-btn-search{
   border: none;
   background-color: transparent;
   padding: 2px;
   color: white;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.menu-item-button {
  background: none;
  border: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  font-size: inherit;
  display: flex;
  align-items: center;
}

.menu-link {
  text-decoration: none;
  color: inherit;
}