.newbranche{
    height: 100%;
    width: 100%;
    background-color: whitesmoke;
}

.newbranche-container{
  margin-top: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
