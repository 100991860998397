.tp1-container {
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
    align-items: flex-start;
    box-shadow:
        0px 19px 38px 0px rgba(0, 0, 0, 0.3),
        0px 15px 12px 0px rgba(0, 0, 0, 0.22);
    width: 100%;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
}

/* Logo */
.logo-container {
    display: flex;
    padding: 16px 0px 16px 16px;
    align-items: center;
    gap: 40px;
    align-self: stretch;
}

.logo-top {
    width: 235px;
    height: 80px;
    flex-shrink: 0;
}

/* Body main */
.tp1-body-container {
    display: flex;
    padding: 0px 26px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

/* Coordonnées company - client */
.coord-container {
    display: flex;
    padding: 8px 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.header-contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.title-contact {
    display: flex;
    padding: 8px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
}

.coord-data {
    display: flex;
    align-items: center;
    gap: 45%;
    align-self: stretch;

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.company-container {
    display: flex;
    height: 96px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width:100%;
}

.company-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
}

.client-container {
    display: flex;
    padding: 4px 20px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
}

.client-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    overflow-wrap: anywhere;
}

.date-effect {
    display: flex;
    min-width: 125px;
    height: 22px;
    padding: 4px 0px;
    align-items: center;
    align-content: center;
    gap: 2px;
    flex-wrap: wrap;
}

/* Contract Details */
.contract-details-container {
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
}

.contract-details {
    display: flex;
    padding: 10px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
}

/* Body */
.tp1-body {
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
}

.tp1-body-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    flex: 1 0 0;
    align-self: stretch;
}

ul {
    list-style-type: disc;
    padding: 0px 16px;
    line-height: normal;
}

.signature {
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
}

.tp1-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.tp1-logo-footer {
    width: 433px;
    height: 150px;
}
