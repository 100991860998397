.headerPage {
    padding-top: 25px ;
}

header {
    display: flex;
    padding-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.logo-prodigeo {
    width: 220px;
    margin: auto
}

.btn-deco {
    position: absolute;
    right: 50px;
    display: block;
    box-sizing: border-box;
    padding: 0 20px;
    height: 45px;
    border-radius: 8px;
    background-color: #00314C;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px; 
}

.btn-deco:hover,
.btn-deco:focus {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}