.container-main {
    width: 100%;
    height: 90vh;
}

.container-second {
    min-height: 90vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.wrap {
    width: 370px;
    overflow: hidden;
    padding: 33px 55px 33px 55px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.logo-prodigeo {
    width: 220px;
    margin-bottom: 5%;
}

.auth-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.auth-title {
    display: block;
    font-size: 26px;
    line-height: 1.2;
    text-align: center;
    color: #00314c;
}

.auth-label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 5px;
    font-weight: 200;
    padding: 2px;
    margin-top: 15px;
}

.auth-input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 12px;
    font-size: 15px;
    background-color: rgba(229, 231, 235, 1);
    border-radius: 8px;
    border: none;
    outline: none;
}

.container-password {
    position: relative;
    width: 100%;
    height: 45px;
}

.auth-password-input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 15px;
    background-color: rgba(229, 231, 235, 1);
    padding-left: 12px;
    font-size: 15px;
    border-radius: 8px;
    border: none;
    outline: none;
}

.password-toggle-icon {
    position: absolute;
    bottom: 43px;
    left: 315px;
    z-index: 1;
}

.auth-input:focus,
.auth-input:hover,
.auth-password-input:focus,
.auth-password-input:hover {
    border-color: #00314c;
    box-shadow: 0 0 5px rgba(0, 49, 76, 0.5);
}

.auth-btn {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 12px;
    margin-bottom: 10px;
    background-color: #00314c;
    color: white;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-top: 30px;
    transition:
        background-color 0.2s,
        box-shadow 0.2s;
}

.auth-btn:hover,
.auth-btn:focus {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}

.forgot-span {
    margin: 5%;
    margin-top: 5%;
}

.forgot-span-text {
    font-size: small;
}

.login-back {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 12px;
    margin-bottom: 10px;
    color: black;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-top: 30px;
    transition:
        background-color 0.2s,
        box-shadow 0.2s;
}

.login-back:hover,
.login-back:focus {
    background-color: #00314c;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}

.btn-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
}

.btn-forgot,
.btn-verify {
    cursor: pointer;
    transition:
        color 0.3s,
        text-decoration 0.3s,
        box-shadow 0.3s;
    text-decoration: none;
    padding: 12px;
    border-radius: 50px;
}

.btn-forgot:hover,
.btn-verify:hover,
.btn-forgot:focus,
.btn-verify:focus {
    border-radius: 16px;
    background: #f6f6f6;
    outline: none;
}
