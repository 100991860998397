.formClient {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.formClient-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
}

.formClient-body {
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 5px;
    background: white;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    min-width: 40%;
    min-height: 60%;
}

.formClient-label {
    padding: 5px;
}

.formClient-input {
    border-radius: 5px;
    padding: 7px;
    outline: none;
    border: 1px solid gray;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
}

.formClient-input-large {
    width: 140px;
}

.formClient-input-narrow {
    width: 120px;
}

.formClient-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 5px;
    padding: 7px;
    outline: none;
    border: 1px solid gray;
    background: white;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="gray" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px 20px;
}

.formClient-input:hover,
.formClient-input:focus,
.formClient-select:hover,
.formClient-select:focus {
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
}

.formClient-center {
    display: flex;
    justify-content: space-between; /* Aligne le premier élément à gauche et le second à droite */
    padding: 10px 0 10px 0;
}

.formClient-footer {
    padding: 45px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.formClient-footer-btn {
    display: block;
    box-sizing: border-box;
    padding: 13px 25px;
    margin: 8px 250px;
    background-color: #00314c;
    color: white;
    border-radius: 8px;
    border: none;
    font-size: larger;
    cursor: pointer;
    transition:
        background-color 0.2s,
        box-shadow 0.2s;
}

.formClient-footer-btn:hover,
.formClient-footer-btn:focus {
    background-color: #005073;
    box-shadow: 0 4px 8px rgba(0, 49, 76, 0.4);
}
